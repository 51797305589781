// export const Item = {
//   AdjustmentNbr: null,
//   BangleSize: null,
//   Brand: "Fred Leighton",
//   CaseLength: null,
//   CaseWidth: null,
//   CenterCaratWeight: null,
//   CenterClarity: null,
//   CenterColor: null,
//   CenterCut: null,
//   CenterEnhancement: null,
//   CenterLabReportNbr: null,
//   CenterOrigin: null,
//   CenterShape: null,
//   CenterStoneNbr: null,
//   CircaDate: null,
//   Clarity: null,
//   ClosureType: null,
//   Collection: "Vintage Jewelry",
//   Color: null,
//   ColorCarats: null,
//   ColorComment: null,
//   ConsignmentCost: "123000.00",
//   ConsignmentItem: "1",
//   CountryOfOrigin: null,
//   Description: "Cushion Ruby and Colored Diamond Pendant Earrings",
//   Diameter: "",
//   DiamondCarats: null,
//   DiamondDetails: null,
//   DimensionTaken: null,
//   DimensionTakenDate: null,
//   DropLength: "",
//   EditorialVideo: null,
//   ElementLength: "",
//   ElementWidth: "",
//   EngraveLimit: null,
//   Feeds: null,
//   FileIdNames:
//     " EJ848 ( AGL ) cts 7.72.pdf:278EF43B-CF78-4BD3-BE1F-F0A96BDF3380|EJ848 ( AGL ) cts 8.64.pdf:A1A514F9-C174-49C9-9D5A-12CEE741122F|EJ848 DIamond Certs.pdf:E2164AA9-3E52-4917-9CAB-D4BABA997833",
//   FinishedDWT: null,
//   FlushBand: null,
//   GemstoneType: null,
//   Height: null,
//   HoldBy: null,
//   HoldCustomerName: null,
//   HoldDate: null,
//   HoldNoteID: null,
//   HoldNoteNbr: null,
//   HoldText: null,
//   IconImageFileID:
//     "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=BB839C7F-2FEF-4D79-80A3-C39087985BDB",
//   IconImageName:
//     "Inventory ID / SKU (E-41618-FL-0-0                )\\E-41618-FL-0-0-icon.JPG",
//   InStockCost: "123000.00",
//   InStockCostCode: "BIGFXX",
//   InStockDate: "2020-02-04T00:00:00",
//   InventoryCategory: "Vintage Jewelry",
//   InventoryID: 98138,
//   InventoryID_2: "E-41618-FL-0-0                ",
//   Invid: 98138,
//   IsAshoka: "0",
//   IsCom: "0",
//   IsFLRound: "0",
//   IsKWCushion: "0",
//   IsKwiat: "0",
//   IsMounted: "0",
//   IsPhantom: "0",
//   IsRtv: "0",
//   IsSemimount: "0",
//   IsSold: "0",
//   IsStuds: "0",
//   IsTiara: "0",
//   IsVirtual: "0",
//   ItemClass: "Purchased Jewelry",
//   ItemStatus: "Active",
//   ItemSubtype: "Earrings - Pendant Earrings",
//   ItemType: "Earrings",
//   JewDataId: 21031385,
//   LargeImageFileID:
//     "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=CC5EBD65-032F-47D3-B42B-9BECBC4D5773",
//   LargeImageName:
//     "Inventory ID / SKU (E-41618-FL-0-0                )\\E-41618-FL-0-0-large.JPG",
//   Length: "",
//   LongDescription:
//     "1 Cushion Ruby 8.64cts, AGL Report CS 85222 states the origin is Burma with heat treatment and minor to moderate heating residues.\n1 Cushion Ruby 7.72cts, AGL Report CS 85223 states the origin is Burma with heat treatment and minor to moderate heating residues.\n16 Yellow, Brown and Pink Pear Shape, Marquise and Round Diamonds 11.62cts, all 16 stones have GIA reports.\n18K White Gold Mountings.",
//   Maker: null,
//   MemoNbr: null,
//   MemoOutCustomer: null,
//   MemoOutCustomerID: null,
//   Metal: "18 karat white gold",
//   MetalNote: null,
//   Milgrain: null,
//   MountedInventoryDBID: null,
//   MountedInventoryID: null,
//   MountingWholesale: null,
//   NewInStockDate: "2020-02-04",
//   OwnCost: null,
//   PartwayEternity: null,
//   Period: "Contemporary",
//   PricingDate: "2020-03-05T00:00:00",
//   PricingMode: "1",
//   ProfitMargin: null,
//   Provenance: null,
//   PurchaseRefNumber: "014808",
//   RFIDValue: "000074846750744756355799",
//   RTVDate: null,
//   ReleaseDate: null,
//   ReportJpgUrls: null,
//   ReportPdfUrls: null,
//   RetailMarkup: "2.00",
//   RetailPrice: "310000.000000",
//   RetailPriceCode: "GBFXXX",
//   RetailSoldCustomer: null,
//   RetailSoldCustomerID: null,
//   RingSize: null,
//   RingSizeValue: null,
//   SearchType: "J",
//   SemiMount: null,
//   SemimountType: null,
//   Serial: "FL41618",
//   SerialLastModifiedDate: "2021-12-09T17:50:00",
//   SerialNumber: "FL41618",
//   SerialSort: "41618",
//   SerialStatus: "OFFICE",
//   SerialVideoLink: null,
//   SettingType: null,
//   ShortDescription: "Cushion Ruby and Colored Diamond Pendant Earrings",
//   SideStoneCaratWeight: null,
//   SizingNotes: null,
//   SoldCustomer: null,
//   SoldCustomerID: null,
//   SoldDate: null,
//   SoldDiscount: null,
//   SoldDiscountCode: "",
//   SoldMargin: null,
//   SoldMarginCode: "",
//   SoldPrice: null,
//   SoldPriceCode: "",
//   SoldRefNumber: null,
//   StatusAmount: null,
//   StatusCustomer: null,
//   StatusCustomerID: null,
//   StatusDate: "2021-03-10T00:00:00",
//   StatusRefNbr: null,
//   StoneOrientation: null,
//   StyleNumber: "E-41618-FL-0-0",
//   StyleStatus: null,
//   StyleVideoLink: null,
//   SubCollection: null,
//   TotalMarkup: "2.52",
//   UsrMountedStockNbr: null,
//   Vendor: "Universal Gems",
//   VendorID: "C000001564",
//   VendorRefDisplay: "",
//   VendorRefNbr: "EJ848",
//   WRShape: null,
//   Warehouse: "OFFICE",
//   WebImage1: null,
//   WebImage2: null,
//   WebImage3: null,
//   WebImage4: null,
//   WebImage5: null,
//   WholesaleMarkup: "1.26",
//   WholesalePrice: "155000.000000",
//   WholesalePriceCode: "BSXFXX",
//   WidthOD: "",
//   dimensionTakenBy: null,
//   hasWebImage: "0",
//   index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
//   isOpenJob: "0",
//   operationType: "Update",
//   transformType: "JewelrySerial",
//   webProductURL:
//     "https://www.fredleighton.com/?opt_product_id=SERFL41618&acu=true&image=https://cdn4.kwiat.com/source-images/large/E-41618-FL-0-0-large.JPG&desc=Earrings, Vintage Jewelry Collection, Style FL41618&price=&serial=FL41618&inv=98138&video=",
//   WebImage1URL: null,
// };

// export const Item = Object.fromEntries(
//   Object.entries({
//     SoldDate: null,
//     ItemStatus: "Active",
//     NewInStockDate: "2019-04-11",
//     UsrMountedStockNbr: null,
//     RetailPrice: "14008000.000000",
//     LargeImageName:
//       "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-large.jpg",
//     RetailPriceCode: "BEFXAFXX",
//     ItemType: "Ring",
//     ConsignmentCost: "8717000.00",
//     RetailSoldCustomerID: null,
//     CenterStoneNbr: "D61902",
//     InventoryID: 78900,
//     ShortDescription:
//       "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold, Cushion Center Diamond of 3.79 Carats, * Color, VVS2 Clarity",
//     StyleVideoLink: null,
//     CenterOrigin: null,
//     OwnCost: "4953.00",
//     SerialStatus: "MEMO",
//     DropLength: "",
//     Milgrain: null,
//     SoldCustomerID: null,
//     SoldPriceCode: "",
//     MemoOutCustomerID: "C000028098",
//     IsTiara: "0",
//     MetalNote: null,
//     ProfitMargin: null,
//     TotalMarkup: "1.61",
//     WholesalePriceCode: "BFXXSGHS",
//     AdjustmentNbr: "001976",
//     index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
//     PricingMode: "1",
//     ReleaseDate: null,
//     HoldNoteNbr: null,
//     PurchaseRefNumber: null,
//     StyleStatus: "Active",
//     BangleSize: null,
//     DimensionTakenDate: null,
//     VendorRefDisplay: "",
//     CenterCaratWeight: "3.79",
//     IconImageName:
//       "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-icon.jpg",
//     IsSold: "0",
//     VendorRefNbr: null,
//     IsStuds: "0",
//     PricingDate: "2021-05-19T00:00:00",
//     IsMounted: "0",
//     HoldDate: null,
//     GemstoneType: "Diamond",
//     operationType: "Update",
//     JewDataId: 20998002,
//     RetailSoldCustomer: null,
//     FlushBand: null,
//     SerialVideoLink: null,
//     ItemClass: "Manufactured Jewelry",
//     FinishedDWT: "2.30",
//     Description:
//       "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold",
//     WebImage1URL:
//       "https://cdn4.kwiat.com/source-images/web/original/F-17751C-0-DIA-18KP-1-1500.jpg",
//     CenterShape: "Cushion",
//     MemoNbr: "M0-309652",
//     Maker: "Kwiat",
//     StatusRefNbr: "M0-309652",
//     SoldDiscountCode: "",
//     HoldCustomerName: null,
//     SearchType: "J",
//     Diameter: "",
//     ElementWidth: "",
//     RingSize: "Ring size 6",
//     VendorID: "C000008346",
//     CenterColor: "*",
//     EngraveLimit: null,
//     hasWebImage: "1",
//     SemimountType: null,
//     WRShape: null,
//     transformType: "JewelrySerial",
//     StyleNumber: "F-17751C-0-DIA-18KP",
//     WebImage1: "F-17751C-0-DIA-18KP-1-1500.jpg",
//     WebImage2: "F-17751C-0-DIA-18KP-2-1500.jpg",
//     Serial: "130695",
//     StatusDate: "2021-11-08T00:00:00",
//     LongDescription: "",
//     DiamondCarats: "4.30",
//     WebImage5: null,
//     Length: "",
//     RFIDValue: "000074627602846893590034",
//     WebImage3: "F-17751C-0-DIA-18KP-3-1500.jpg",
//     WebImage4: "F-17751C-0-DIA-18KP-4-FS-1500.jpg",
//     CenterLabReportNbr: null,
//     IsKwiat: "1",
//     Feeds: null,
//     IsKWCushion: "0",
//     WholesaleMarkup: "1.15",
//     EditorialVideo: null,
//     SoldPrice: null,
//     ItemSubtype: "Rings - Diamond Ring",
//     Provenance: null,
//     SerialLastModifiedDate: "2021-12-13T22:15:00",
//     ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2195701309.jpg",
//     SemiMount: false,
//     SoldCustomer: null,
//     CountryOfOrigin: "USA",
//     SoldMarginCode: "",
//     Metal: "18 karat rose gold",
//     StatusAmount: 10005375,
//     StatusCustomer: "Artisan Global Alliance",
//     CenterClarity: "VVS2",
//     FileIdNames: null,
//     Invid: 78900,
//     MountedInventoryID: null,
//     SoldMargin: null,
//     DimensionTaken: "0",
//     Color: "Blue Dia",
//     Period: "Contemporary",
//     CaseWidth: null,
//     StoneOrientation: null,
//     ElementLength: "",
//     IsAshoka: "0",
//     SettingType: null,
//     RingSizeValue: "6",
//     MountingWholesale: "5375.00",
//     webProductURL:
//       "https://kwiat.com/?opt_product_id=INV78900&https://cdn4.kwiat.com/source-images/web/product/F-17751C-0-DIA-18KP-1-1500-product@2x.jpg",
//     SizingNotes: null,
//     isOpenJob: "0",
//     Brand: "Kwiat",
//     HoldText: null,
//     SerialNumber: "130695",
//     StatusCustomerID: "C000028098",
//     WholesalePrice: "10005375.000000",
//     IconImageFileID:
//       "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=5558A05F-6F64-439F-86DA-30EFA2D76879",
//     Collection: "Bridal",
//     HoldBy: null,
//     CaseLength: null,
//     SoldRefNumber: null,
//     Warehouse: "MEMO",
//     SoldDiscount: null,
//     IsVirtual: "0",
//     InStockCostCode: "AHIBLSG",
//     CenterEnhancement: null,
//     RTVDate: null,
//     ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2195701309.pdf",
//     ConsignmentItem: "0",
//     MountedInventoryDBID: null,
//     ClosureType: null,
//     IsFLRound: "0",
//     WidthOD: "1.70 MM",
//     Height: null,
//     InStockDate: "2019-04-11T00:00:00",
//     MemoOutCustomer: "Artisan Global Alliance",
//     SubCollection: "Bridal - The Kwiat Setting",
//     HoldNoteID: null,
//     IsSemimount: "0",
//     dimensionTakenBy: null,
//     InventoryID_2: "F-17751C-0-DIA-18KP           ",
//     ColorComment: null,
//     CircaDate: null,
//     Vendor: "Kwiat Enterprises, LLC",
//     Clarity: "VVS2-SI2",
//     IsCom: "0",
//     CenterCut: null,
//     RetailMarkup: "1.40",
//     IsRtv: "0",
//     SerialSort: "130695",
//     SideStoneCaratWeight: "0.51",
//     DiamondDetails: "1CU54BR",
//     ColorCarats: null,
//     InventoryCategory: "Solitaire Rings",
//     InStockCost: "8721953.00",
//     IsPhantom: "0",
//     PartwayEternity: null,
//     LargeImageFileID:
//       "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=D1522C19-CA03-4A96-AABE-7AE164667A3F",
//   }).sort()
// );

// export const Item = {
//   AdjustmentNbr: "001976",
//   BangleSize: null,
//   Brand: "Kwiat",
//   CaseLength: null,
//   CaseWidth: null,
//   CenterCaratWeight: "3.79",
//   CenterClarity: "VVS2",
//   CenterColor: "*",
//   CenterCut: null,
//   CenterEnhancement: null,
//   CenterLabReportNbr: null,
//   CenterOrigin: null,
//   CenterShape: "Cushion",
//   CenterStoneNbr: "D61902",
//   CircaDate: null,
//   Clarity: "VVS2-SI2",
//   ClosureType: null,
//   Collection: "Bridal",
//   Color: "Blue Dia",
//   ColorCarats: null,
//   ColorComment: null,
//   ConsignmentCost: "8717000.00",
//   ConsignmentItem: "0",
//   CountryOfOrigin: "USA",
//   Description:
//     "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold",
//   Diameter: "",
//   DiamondCarats: "4.30",
//   DiamondDetails: "1CU54BR",
//   DimensionTaken: "0",
//   DimensionTakenDate: null,
//   DropLength: "",
//   EditorialVideo: null,
//   ElementLength: "",
//   ElementWidth: "",
//   EngraveLimit: null,
//   Feeds: null,
//   FileIdNames: null,
//   FinishedDWT: "2.30",
//   FlushBand: null,
//   GemstoneType: "Diamond",
//   Height: null,
//   HoldBy: null,
//   HoldCustomerName: null,
//   HoldDate: null,
//   HoldNoteID: null,
//   HoldNoteNbr: null,
//   HoldText: null,
//   IconImageFileID:
//     "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=5558A05F-6F64-439F-86DA-30EFA2D76879",
//   IconImageName:
//     "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-icon.jpg",
//   InStockCost: "8721953.00",
//   InStockCostCode: "AHIBLSG",
//   InStockDate: "2019-04-11T00:00:00",
//   InventoryCategory: "Solitaire Rings",
//   InventoryID: 78900,
//   InventoryID_2: "F-17751C-0-DIA-18KP           ",
//   Invid: 78900,
//   IsAshoka: "0",
//   IsCom: "0",
//   IsFLRound: "0",
//   IsKWCushion: "0",
//   IsKwiat: "1",
//   IsMounted: "0",
//   IsPhantom: "0",
//   IsRtv: "0",
//   IsSemimount: "0",
//   IsSold: "0",
//   IsStuds: "0",
//   IsTiara: "0",
//   IsVirtual: "0",
//   ItemClass: "Manufactured Jewelry",
//   ItemStatus: "Active",
//   ItemSubtype: "Rings - Diamond Ring",
//   ItemType: "Ring",
//   JewDataId: 20998002,
//   LargeImageFileID:
//     "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=D1522C19-CA03-4A96-AABE-7AE164667A3F",
//   LargeImageName:
//     "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-large.jpg",
//   Length: "",
//   LongDescription: "",
//   Maker: "Kwiat",
//   MemoNbr: "M0-309652",
//   MemoOutCustomer: "Artisan Global Alliance",
//   MemoOutCustomerID: "C000028098",
//   Metal: "18 karat rose gold",
//   MetalNote: null,
//   Milgrain: null,
//   MountedInventoryDBID: null,
//   MountedInventoryID: null,
//   MountingWholesale: "5375.00",
//   NewInStockDate: "2019-04-11",
//   OwnCost: "4953.00",
//   PartwayEternity: null,
//   Period: "Contemporary",
//   PricingDate: "2021-05-19T00:00:00",
//   PricingMode: "1",
//   ProfitMargin: null,
//   Provenance: null,
//   PurchaseRefNumber: null,
//   RFIDValue: "000074627602846893590034",
//   RTVDate: null,
//   ReleaseDate: null,
//   ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2195701309.jpg",
//   ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2195701309.pdf",
//   RetailMarkup: "1.40",
//   RetailPrice: "14008000.000000",
//   RetailPriceCode: "BEFXAFXX",
//   RetailSoldCustomer: null,
//   RetailSoldCustomerID: null,
//   RingSize: "Ring size 6",
//   RingSizeValue: "6",
//   SearchType: "J",
//   SemiMount: false,
//   SemimountType: null,
//   Serial: "130695",
//   SerialLastModifiedDate: "2021-12-13T22:15:00",
//   SerialNumber: "130695",
//   SerialSort: "130695",
//   SerialStatus: "MEMO",
//   SerialVideoLink: null,
//   SettingType: null,
//   ShortDescription:
//     "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold, Cushion Center Diamond of 3.79 Carats, * Color, VVS2 Clarity",
//   SideStoneCaratWeight: "0.51",
//   SizingNotes: null,
//   SoldCustomer: null,
//   SoldCustomerID: null,
//   SoldDate: null,
//   SoldDiscount: null,
//   SoldDiscountCode: "",
//   SoldMargin: null,
//   SoldMarginCode: "",
//   SoldPrice: null,
//   SoldPriceCode: "",
//   SoldRefNumber: null,
//   StatusAmount: 10005375,
//   StatusCustomer: "Artisan Global Alliance",
//   StatusCustomerID: "C000028098",
//   StatusDate: "2021-11-08T00:00:00",
//   StatusRefNbr: "M0-309652",
//   StoneOrientation: null,
//   StyleNumber: "F-17751C-0-DIA-18KP",
//   StyleStatus: "Active",
//   StyleVideoLink: null,
//   SubCollection: "Bridal - The Kwiat Setting",
//   TotalMarkup: "1.61",
//   UsrMountedStockNbr: null,
//   Vendor: "Kwiat Enterprises, LLC",
//   VendorID: "C000008346",
//   VendorRefDisplay: "",
//   VendorRefNbr: null,
//   WRShape: null,
//   Warehouse: "MEMO",
//   WebImage1: "F-17751C-0-DIA-18KP-1-1500.jpg",
//   WebImage1URL:
//     "https://cdn4.kwiat.com/source-images/web/original/F-17751C-0-DIA-18KP-1-1500.jpg",
//   WebImage2: "F-17751C-0-DIA-18KP-2-1500.jpg",
//   WebImage3: "F-17751C-0-DIA-18KP-3-1500.jpg",
//   WebImage4: "F-17751C-0-DIA-18KP-4-FS-1500.jpg",
//   WebImage5: null,
//   WholesaleMarkup: "1.15",
//   WholesalePrice: "10005375.000000",
//   WholesalePriceCode: "BFXXSGHS",
//   WidthOD: "1.70 MM",
//   dimensionTakenBy: null,
//   hasWebImage: "1",
//   index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
//   isOpenJob: "0",
//   operationType: "Update",
//   transformType: "JewelrySerial",
//   webProductURL:
//     "https://kwiat.com/?opt_product_id=INV78900&https://cdn4.kwiat.com/source-images/web/product/F-17751C-0-DIA-18KP-1-1500-product@2x.jpg",
// };

// export const Item = {
//   AdjustmentNbr: null,
//   BangleSize: null,
//   Brand: "Kwiat",
//   CaseLength: null,
//   CaseWidth: null,
//   CenterCaratWeight: null,
//   CenterClarity: null,
//   CenterColor: null,
//   CenterCut: null,
//   CenterEnhancement: null,
//   CenterLabReportNbr: null,
//   CenterOrigin: null,
//   CenterShape: null,
//   CenterStoneNbr: null,
//   CircaDate: null,
//   Clarity: "SI1",
//   ClosureType: null,
//   Collection: "Legacy",
//   Color: "DE",
//   ColorCarats: null,
//   ColorComment: null,
//   ConsignmentCost: null,
//   ConsignmentItem: "0",
//   CountryOfOrigin: "USA",
//   Description: "heart shape/pear shape toi et moi ring",
//   Diameter: "",
//   DiamondCarats: "5.64",
//   DiamondDetails: "1HS 1PS",
//   DimensionTaken: "1",
//   DimensionTakenDate: "2021-06-03T00:00:00",
//   DropLength: "",
//   EditorialVideo: null,
//   ElementLength: "",
//   ElementWidth: "",
//   EngraveLimit: null,
//   Feeds: null,
//   FileIdNames: null,
//   FinishedDWT: "3.10",
//   FlushBand: "0",
//   GemstoneType: null,
//   Height: null,
//   HoldBy: "Pino, Ali",
//   HoldCustomerName: "Marcy Debson",
//   HoldDate: "2021-03-24T00:00:00",
//   HoldNoteID: 9386,
//   HoldNoteNbr: "HN-00009360",
//   HoldText: "Item Created from production order # 210774",
//   IconImageFileID: null,
//   IconImageName: null,
//   InStockCost: "62709.32",
//   InStockCostCode: "TIHFL",
//   InStockDate: "2021-03-24T00:00:00",
//   InventoryCategory: "Kwiat Collections",
//   InventoryID: 100010,
//   InventoryID_2: "F-30182-0-DIA-18KP            ",
//   Invid: 100010,
//   IsAshoka: "0",
//   IsCom: "0",
//   IsFLRound: "0",
//   IsKWCushion: "0",
//   IsKwiat: "1",
//   IsMounted: "0",
//   IsPhantom: "0",
//   IsRtv: "0",
//   IsSemimount: "0",
//   IsSold: "1",
//   IsStuds: "0",
//   IsTiara: "0",
//   IsVirtual: "0",
//   ItemClass: "Manufactured Jewelry",
//   ItemStatus: "Active",
//   ItemSubtype: "Rings - Design Ring",
//   ItemType: "Ring",
//   JewDataId: 20876412,
//   LargeImageFileID: null,
//   LargeImageName: null,
//   Length: "",
//   LongDescription: null,
//   Maker: "Kwiat",
//   MemoNbr: null,
//   MemoOutCustomer: null,
//   MemoOutCustomerID: null,
//   Metal: "18 karat rose gold",
//   MetalNote: null,
//   Milgrain: null,
//   MountedInventoryDBID: null,
//   MountedInventoryID: null,
//   MountingWholesale: null,
//   NewInStockDate: "2021-03-24",
//   OwnCost: "62709.32",
//   PartwayEternity: null,
//   Period: "Contemporary",
//   PricingDate: "2021-03-24T00:00:00",
//   PricingMode: "1",
//   ProfitMargin: "0.1041525714285714285",
//   Provenance: null,
//   PurchaseRefNumber: null,
//   RFIDValue: null,
//   RTVDate: null,
//   ReleaseDate: "2022-03-24T00:00:00",
//   ReportJpgUrls:
//     " https://cdn.kwiat.com/kwiat/certs-jpgs/6214070639.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/6351307894.jpg",
//   ReportPdfUrls:
//     " https://cdn.kwiat.com/kwiat/certs-pdfs/6214070639.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/6351307894.pdf",
//   RetailMarkup: "1.40",
//   RetailPrice: "96600.000000",
//   RetailPriceCode: "LTXFX",
//   RetailSoldCustomer: null,
//   RetailSoldCustomerID: null,
//   RingSize: "Ring size 5",
//   RingSizeValue: "5",
//   SearchType: "J",
//   SemiMount: null,
//   SemimountType: null,
//   Serial: "137481",
//   SerialLastModifiedDate: "2021-06-03T00:00:00",
//   SerialNumber: "137481",
//   SerialSort: "137481",
//   SerialStatus: "SOLD",
//   SerialVideoLink: null,
//   SettingType: null,
//   ShortDescription: "heart shape/pear shape toi et moi ring",
//   SideStoneCaratWeight: null,
//   SizingNotes: null,
//   SoldCustomer: "Fran Lebovitz",
//   SoldCustomerID: "C000028530",
//   SoldDate: "2021-03-24T00:00:00",
//   SoldDiscount: "27.54",
//   SoldDiscountCode: "IH",
//   SoldMargin: "7290.68",
//   SoldMarginCode: "HILF",
//   SoldPrice: "70000.00",
//   SoldPriceCode: "HFXXX",
//   SoldRefNumber: "INV-009625",
//   StatusAmount: "70000.00",
//   StatusCustomer: "Fran Lebovitz",
//   StatusCustomerID: "C000028530",
//   StatusDate: "2021-03-24T00:00:00",
//   StatusRefNbr: "INV-009625",
//   StoneOrientation: null,
//   StyleNumber: "F-30182-0-DIA-18KP",
//   StyleStatus: null,
//   StyleVideoLink: null,
//   SubCollection: null,
//   TotalMarkup: "1.54",
//   UsrMountedStockNbr: null,
//   Vendor: "Kwiat Enterprises, LLC",
//   VendorID: "C000008346",
//   VendorRefDisplay: "",
//   VendorRefNbr: null,
//   WRShape: null,
//   Warehouse: null,
//   WebImage1: null,
//   WebImage2: null,
//   WebImage3: null,
//   WebImage4: null,
//   WebImage5: null,
//   WholesaleMarkup: "1.10",
//   WholesalePrice: "69000.000000",
//   WholesalePriceCode: "TLFXX",
//   WidthOD: "",
//   dimensionTakenBy: "Klass, Sarah",
//   hasWebImage: "0",
//   index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
//   isOpenJob: "0",
//   operationType: "Update",
//   transformType: "JewelrySerial",
//   webProductURL:
//     "https://kwiat.com/?opt_product_id=SER137481&acu=true&image=&desc=Serial 137481&price=&serial=137481&inv=100010&video=",
// };

export const Item = {
  AdjustmentNbr: null,
  Brand: "Kwiat",
  ConsignmentCost: "8697.60",
  ConsignmentItem: null,
  Depth: "3.37",
  DepthP: "61.70",
  Description: "Ashoka Dia, 1.50-1.99 cts",
  DiaVideoImage: "https://cdn.kwiat.com/kwiat/v360/imaged/62198/still.jpg",
  DiaVideoLink:
    "https://d2h5mqhatdlv4d.cloudfront.net/lds-video-sharing/Vision360.html?d=62198",
  DiamondCaratWeight: "1.510000",
  DiamondClarityRange: "VS1",
  DiamondClarityRange_New: 25,
  DiamondColorRange: "I",
  DiamondColorRange_New: 30,
  DiamondCut: null,
  DiamondSizeCategory: "1.50-1.69 carat size range",
  DiamondTiaraInscription: "0",
  FancyColor: null,
  FancyColorGIA: null,
  FancyColorIntensity: null,
  FileIdNames:
    " 6331176259.pdf:D469B6F5-D736-4562-A760-2C8A3EBA274A|D62198-still.jpg:6BD9FE00-F241-4E4B-BDCF-51F73A420AB7",
  GIANumber: "6331176259",
  GIAReportDate: "2019-07-03T00:00:00",
  GemstoneType: "Diamond",
  Giapdfurl: "https://cdn.kwiat.com/kwiat/certs-pdfs/6331176259.pdf",
  Giashape: "Round-Cornered Rectangular Modified Brilliant",
  GradingLab: "GIA",
  HoldBy: null,
  HoldCustomerName: null,
  HoldDate: null,
  HoldNoteID: null,
  HoldNoteNbr: null,
  HoldText: null,
  IconImageFileID:
    "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=6BD9FE00-F241-4E4B-BDCF-51F73A420AB7",
  IconImageName: "D62198-still.jpg",
  InStockCost: "8697.60",
  InStockCostCode: "ATLH",
  InStockCostPerCarat: "5760.00",
  InStockCostPerCaratCode: "SHTF",
  InStockDate: "2019-11-07T00:00:00",
  InStockRelToList: "-10.00",
  Inscription: "GIA 6331176259, [WG SYMBOL] ASHOKA®",
  InternalUseComments: null,
  InventoryCategory: "Loose Stones",
  InventoryID: 85273,
  InventoryID_2: null,
  Invid: 85273,
  IsFLCushion: "0",
  IsFLRound: "0",
  IsHoldSold: "0",
  IsKWCushion: "0",
  IsMounted: "0",
  IsPhantom: "0",
  IsRtv: "1",
  IsSold: "0",
  IsTiara: "0",
  IsVirtual: "0",
  ItemClass: "Loose Diamonds",
  ItemStatus: "Active",
  KeyDatesID: 11459254,
  LabReportNbr: "6331176259",
  LargeImageFileID:
    "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=6BD9FE00-F241-4E4B-BDCF-51F73A420AB7",
  LargeImageName: "D62198-still.jpg",
  LastModifiedOn: "2020-11-20T00:00:00",
  Length: "8.58",
  LongDesc: null,
  LotSerialNbr: "D62198",
  MemoOutCustomer: null,
  MemoOutCustomerID: null,
  MountedInventoryDBID: null,
  MountedInventoryID: null,
  MountedItemType: null,
  MountedStockNumber: null,
  OwnCost: null,
  Polish: "EX",
  PricingDate: "2020-09-25T00:00:00",
  PricingMode: "2",
  ProfitMargin: null,
  PurchaseRefNumber: "011862",
  RFIDOldValue1: null,
  RFIDOldValue2: null,
  RFIDValue: null,
  RTVInitial: "Tran, Nhan",
  RapListPerCarat: "6400.00",
  RapListPerCaratCode: "TEFX",
  ReleaseDate: null,
  ReportJpgUrls: "https://cdn.kwiat.com/kwiat/certs-jpgs/6331176259.jpg",
  RetailMarkup: "1.65",
  RetailPrice: "17600.000000",
  RetailPriceCode: "BHTFX",
  RetailPricePerCarat: "11655.63",
  RetailRelToList: "82.12",
  RetailSoldCustomer: null,
  RetailSoldCustomerID: null,
  Rtvdate: "2020-11-20T00:00:00",
  SerialNumber: "D62198",
  SerialSort: "62198",
  SerialStatus: "RTV",
  SerialVideoLink:
    "https://cdn.kwiat.com/kwiat/v360/imaged/62198/62198.html?sm=0&btn=0&z=0&sv=1&zoomslide=0&isFullLoad=1",
  Shape: "Ashoka",
  ShortDesc: "Ashoka Diamond, 1.51cts, I/VS1, GIA",
  Siteid: null,
  SoldCustomer: null,
  SoldCustomerID: null,
  SoldDate: null,
  SoldDiscount: null,
  SoldDiscountCode: "",
  SoldMargin: null,
  SoldMarginCode: "",
  SoldPrice: null,
  SoldPriceCode: "",
  SoldRefNumber: null,
  StatusAmount: null,
  StatusCustomer: "William Goldberg Diamond Corp.",
  StatusCustomerID: "C000007103",
  StatusDate: "2020-11-20T00:00:00",
  StatusRefNbr: null,
  StoneCulet: "None",
  StoneFluoresence: "None",
  StoneFluoresenceColor: null,
  StoneGirdle: "STK to ETK",
  StoneRatio: "1.57",
  StyleNumber: "DIA-AK-150",
  StyleVideoLink: null,
  Symmetry: "EX",
  TableP: "65.00",
  Vendor: "William Goldberg Diamond Corp.",
  VendorID: "C000007103",
  VendorRefNbr: "24957",
  Warehouse: null,
  WholesaleMarkup: "1.22",
  WholesalePerCarat: "7040.00",
  WholesalePerCaratCode: "HFEF",
  WholesalePrice: "10630.400000",
  WholesalePriceCode: "BFTGF",
  WholesalePricePerCarat: "7040.00",
  WholesaleRelToList: "10.00",
  Width: "5.46",
  index: "kwfl-acumatica-catalog-v7-prod-diamondserial2",
  operationType: "Update",
  transformType: "DiamondSerial",
};
